<template>
  <ion-header :translucent="true" id="main-header">
    <ion-toolbar>
      <ion-title id="main-header-title">{{ title }}</ion-title>
      <ion-title id="main-header-subtitle" size="small">{{
        subTitle
      }}</ion-title>
      <ion-buttons id="main-header-buttons" slot="end">

        <ion-back-button></ion-back-button>

        <ion-button @click="goTo('HomePage')">
          <ion-icon size="large" :icon="home"></ion-icon>
        </ion-button>
        <slot name="header-buttons"> </slot>

        <ion-button @click="logOutMethod">
          <ion-icon size="large" :icon="logOut"></ion-icon>
        </ion-button>

      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>


<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
} from "@ionic/vue";

import { home, logOut } from "ionicons/icons";

export default {
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
  },
  props: [
    "title", // Nome da página ou funcionalidade (Ex: Conferência de cargas)
    "subTitle", // Nome da ferramenta ou sistema (Ex: Checon Logística)
  ],
  data() {
    return {
      home,
      logOut
    }
  },
  methods: {
    goTo(pageName) {
      this.$router.push({ name: pageName });
    },
    async logOutMethod() {
      await this.$store.dispatch('auth/logOut')
      this.$router.replace({ name: 'Login' })
    }
  }
};
</script>


<style scoped>
ion-img::part(image) {
  width: 4rem;
  /* or whatever value */
  /* you can set the height value too */
}

.toolbar-text-area {
  display: flex;
}

@media (max-width: 600px) {
  #main-header-title {
    display: none;
  }

  #main-header-subtitle {
    display: none;
  }
}

@media print {
  #main-header {
    display: none;
  }
}</style>