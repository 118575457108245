<template>
  <ion-page>
    <main-header :title="title" :subTitle="subTitle">
      <template #header-buttons>
        <slot name="header-buttons"></slot>
      </template>
    </main-header>

    <ion-content :fullscreen="true">
      <slot name="content">
        <ion-header collapse="condense">
          <ion-toolbar>
            <ion-title size="large">Blank</ion-title>
          </ion-toolbar>
        </ion-header>

        <div id="container">
          <strong>Ready to create an app?</strong>
          <p>
            Start with Ionic
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ionicframework.com/docs/components"
              >UI Components</a
            >
          </p>
        </div>
      </slot>
    </ion-content>
  </ion-page>
</template>

<script>
import MainHeader from "@/components/global_base/MainHeader.vue";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default {
  components: {
    MainHeader,
    // MainFooter,
    IonContent,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
  },
  props: {
    title: {
      type: String,
      default: "Sistemas Internos",
    },
    subTitle: {
      type: String,
      default: "Venturini & Checon Dist de Bebidas Ltda",
    },
  },
  mounted() {
    document.title = this.title;
  },
};
</script>



<style scoped>
#loading-text{
  font-size: 1.7rem;
  padding: 10px 20px;
  display: inline-block;
}
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
