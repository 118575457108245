import axios, { AxiosError } from 'axios';
import ionicStorage from '../ionic_storage/ionicStorage.js';
import { RequestHandler } from '../utils/customHandlers';

const initialCredentials = {
    "id": 0,
    "first_name": "Nome",
    "last_name": "do Usuário",
    "profile": {
        "role": "Null",
        "department": "Null",
        "code": 0,
        "require_password_update": 0,
        "enabled_systems": {
            "system_name": {
                "label": "system description",
                "enabled": {
                    "value": false,
                    "label": "permission description"
                }
            }
        }
    }
}

const authModule = {
    namespaced: true,
    state() {
        return {
            userCredentials: JSON.parse(JSON.stringify(initialCredentials)),
            accessToken: '',
            refreshToken: '',
            tokenTimestamp: 0,
            dataRehydrationInCourse: false,
            // expirationDate: '08/11/1989',
            //Converter para data: new Date(x), onde x = ('mm-dd-yyyy') se string, ou (yyyy, mm, dd) se integer.
        }
    },
    getters: {
        isAuthenticated(state, getters) {
            // const now = new Date()
            // const expired = new Date(state.expirationDate) > now ? false : true
            // if (state.accessToken.length < 2 || expired ) {
            if (state.accessToken.length < 2 || getters.tokenIsExpired) {
                return false
            } else {
                return true
            }
        },
        tokenIsExpired() {
            // return (new Date(state.expirationDate) < new Date())
            return false
        },
        getToken(state, getters) {
            if (getters.tokenIsExpired) {
                // Try to get a new token
                return 'Here is your new token!'
            } else {
                return state.accessToken
            }
        },
        getTokenTimestamp(state) {
            if (state.tokenTimestamp instanceof Date) {
                return state.tokenTimestamp
            } else {
                return false
            }
        },
        userCredentials(state) {
            return state.userCredentials
            // if ( 'id' in state.userCredentials ){
            //     return state.userCredentials
            // } else {
            //     return false
            // }
        },
        enabledSystems(state) {
            return state.userCredentials.profile.enabled_systems
        }
    },

    actions: {
        async getUserList(context){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/'
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getProfileList(context){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/get_profiles'
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async changePassword(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/change_password/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async updateOwnPassword(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/update_own_password/'
            const response = await rH.createTarget(targetUrl, payload)

            if (response.status == 202) {
                context.commit('updateOwnPassword')
            }

            return response
        },
        async changeProfile(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/change_profile/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async changeUserData(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/change_userdata/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async createUser(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/create_user/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async createProfile(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/create_profile/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async editEnabledSystems(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = 'api/profiles/edit_enabled_systems/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async getEnabledSystemsBlueprint(context){
            const rH = new RequestHandler(context)
            const targetUrl = '/api/profiles/enabled_systems_blueprint/'
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async logOut({ dispatch, commit }) {
            await dispatch('clearIonicStorageAuthData')
            commit('resetAuthData')
            commit('resetUserCredentials')
        },
        async setUserCredentials({ commit, dispatch, getters }, verifyToken = true) {

            if (verifyToken){
                const tokenIsValid = await dispatch('checkTokenIsValid')
                if (!tokenIsValid) {
                    const result = { status: 404 }
                    return result
                }
            }

            const token = getters['getToken']
            const targetUrl = 'api/profiles/get_me/'
            const config = {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }

            try {
                const response = await axios.get(targetUrl, config)
                commit('setUserCredentials', response.data)
            } catch (er) {
                return false
            }
        },
        setAuthData({ commit }, payload) {
            //  This action shall be called in the login method, in order to
            //  enable state rehydration through ionicStorage (read state_rehydration.md)
            const tokenTimestamp = new Date()
            const authData = { ...payload, "tokenTimestamp": tokenTimestamp}
            commit('setAuthData', authData)
        },
        async clearIonicStorageAuthData() {
            await ionicStorage.remove('jsonAuthData')
            await ionicStorage.remove('authData')
        },
        async validateStoredAuthData({ dispatch }) {
            const jsonAuthData = await ionicStorage.get('jsonAuthData')
            const storedAccessToken = JSON.parse(jsonAuthData)
            const urlAuth = 'api/auth/token/verify/'

            if (storedAccessToken) {
                const data = {
                    token: storedAccessToken.access
                }
                try {
                    await axios.post(urlAuth, data)
                    return true
                } catch (er) {
                    await dispatch('clearIonicStorageAuthData')
                    return false
                }
            } else {
                await dispatch('clearIonicStorageAuthData')
                return false
            }
        },
        async checkTokenIsValid({ state, getters, dispatch }) {
            const urlAuth = 'api/auth/token/verify/'
            const data = {
                token: state.accessToken
            }

            return axios.post(urlAuth, data)
                .then(
                    // Resolved
                    (result) => {
                        if (result.status == 200) {
                            // Check if user credentials refresh is required
                            const isAuthenticated = getters['isAuthenticated']
                            const creds = getters['userCredentials']
                            if (isAuthenticated && Object.prototype.hasOwnProperty.call(creds, "id") && creds['id'] == 0) {
                                const verifyToken = false
                                dispatch("setUserCredentials", verifyToken).then(() => {
                                    return true
                                })
                            } else {
                                return true
                            }
                        }
                    })
                .catch(
                    //  Rejected
                    (er) => {
                        if (er instanceof AxiosError && Object.prototype.hasOwnProperty.call(er, "response")) {
                            const { response } = er
                            if (response.status == 401) {
                                return dispatch("refreshAccessToken").then((result) => result)
                            } else if (response.status == 400) { // Bad Request
                                dispatch("logOut")
                                return false
                            }
                        } else {
                            dispatch("logOut")
                            return false
                        }
                    })
        },
        async refreshAccessToken({ state, dispatch }) {
            const urlAuth = 'api/auth/token/refresh/'
            const data = {
                refresh: state.refreshToken
            }

            // const response = await axios.post(urlAuth, data)
            return axios.post(urlAuth, data)
                .then(
                    (response) => {
                        if (response.status == 200) {
                            dispatch('setAuthData', response.data)
                            return true
                        }
                    }
                )
                .catch(
                    () => {
                        dispatch('logOut')
                        return false
                    }
                )
        },
        async login({ dispatch }, payload) {
            /* 
                    payload layout:
                {
                    username: 'xxxx',
                    password: 'xxxx'   
                } 
            */

            const urlAuth = 'api/auth/token/'
            try {
                const response = await axios.post(urlAuth, payload)
                await dispatch('setAuthData', response.data)
                await dispatch('setUserCredentials')
                return response
            } catch (e) {
                if (e instanceof AxiosError) {
                    if (e['code'] == 'ERR_NETWORK') {
                        return { status: 500 }
                    } else {
                        return e.response
                    }
                } else {
                    return { status: 500 }
                }
            }
        },
        async rehydrateAuthData({ state, commit }) {

            if (state.dataRehydrationInCourse) {
                return false
            }
            
            state.dataRehydrationInCourse = true
            
            const jsonAuthData = await ionicStorage.get('jsonAuthData')
            const authData = JSON.parse(jsonAuthData)

            if (authData) {
                commit('setAuthData', authData)
            }

            state.dataRehydrationInCourse = false
        },
    },


    mutations: {
        setAuthData(state, payload) {
            const accessToken = payload['access']
            const refreshToken = payload['refresh'] || state.refreshToken
            const tokenTimestamp = payload['tokenTimestamp'] || state.tokenTimestamp

            state.accessToken = accessToken
            state.refreshToken = refreshToken
            state.tokenTimestamp = tokenTimestamp
        },
        resetAuthData(state) {
            state.accessToken = ''
            state.refreshToken = ''
        },
        setUserCredentials(state, payload) {
            state.userCredentials = payload
        },
        resetUserCredentials(state) {
            state.userCredentials = JSON.parse(JSON.stringify(initialCredentials))
        },
        updateOwnPassword(state) {
            state.userCredentials['profile']['require_password_update'] = false
        }
    },
}

export default authModule