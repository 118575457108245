import { RequestHandler } from '../utils/customHandlers';

const bankRecordsModule = {
    namespaced: true,
    state() {
        return {

        }
    },
    getters: {

    },
    actions: {
        async sendOfxFile(context, payload) {
            const token = context.rootGetters['auth/getToken']
            const targetUrl = 'api/bank-records/'
            const config = {
                headers: {
                    Authorization: 'Bearer ' + token,
                    "Content-Type": 'multipart/form-data',
                }
            }

            const rH = new RequestHandler(context)
            const response = await rH.createTarget(targetUrl, payload, config)
            return response
        },

        async getBankRecords(context, payload) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/bank-records/?date_range=${payload}`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getConciliateableBankRecords(context, payload) {
            // async getBankRecordsAtValueAndDate(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = `api/bank-records/?at_value=${payload.value}&at_date=${payload.date}&only_conciliateable=1`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getCheckToReconcileBankRecords(context, payload) {
            // async getUnidentifiedBankRecords(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = `api/bank-records/${payload}/check_to_reconcile/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async confirmToReconcileBankRecords(context, payload) {
            // async confirmUnidentifiedBankRecords(context, payload){
            const token = context.rootGetters['auth/getToken']
            const targetUrl = 'api/bank-records/confirm_to_reconcile/'
            const config = {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }

            const rH = new RequestHandler(context)
            const response = await rH.createTarget(targetUrl, payload, config)
            return response
        },
        async conciliateBankRecord(context, payload) {
            const targetUrl = `api/bank-records/conciliate_target_receipt/`

            const rH = new RequestHandler(context)
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async unconciliateBankRecord(context, payload) {
            const targetUrl = `api/bank-records/unconciliate_target_receipt/`

            const rH = new RequestHandler(context)
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
    },
    mutations: {

    }
}

export default bankRecordsModule