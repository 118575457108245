<template>
  <MainBase>
    <template #content>
      <ion-card>
        <ion-card-content>
          <ion-card-title> Sistema Integrado de Cobrança </ion-card-title>
          <ion-card-subtitle>
            Selecione a aplicação desejada:
          </ion-card-subtitle>
          <div class="options-div">
            
            <ion-button 
              class="option-btn" 
              v-if="canAccessReceipts"
              @click="goTo('NewReceipt')"
              >Comprovantes de pagamento
            </ion-button>

            <ion-button
              class="option-btn"
              v-if="canAccessBankRecords"
              @click="goTo('BankRecordsByDate')"
              >Extratos bancários</ion-button
            >
            <ion-button
              class="option-btn"
              v-if="canAccessUserManagement"
              @click="goTo('UserManagement')"
              >Manutenção de usuários</ion-button
            >

            <ion-button
              class="option-btn"
              v-if="canAccessCustomerCreation"
              @click="goTo('CreateCustomer')"
              >Cadastro de Clientes</ion-button
            >

            <ion-button
              class="option-btn"
              v-if="canAccessCrm"
              @click="goTo('RelevantInteractionFollowup')"
              >CRM</ion-button
            >

            <ion-button
              class="option-btn"
              v-if="devEnv"
              @click="goTo('Testando')"
              color="success"
              >Testes</ion-button
            >
          </div>
        </ion-card-content>
      </ion-card>
    </template>
  </MainBase>
</template>

<script setup>
import MainBase from "@/components/global_base/MainBase.vue";

import {
  IonButton,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/vue";

import { computed } from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();

const userCredentials =  computed(()=>{
  return store.getters["auth/userCredentials"];
}) 

function accessCheck(systemName = ""){
  try {
    const usrDept = userCredentials.value.profile.department;
    if (usrDept == "diretoria"){
      return true
    }
  
    if (systemName in userCredentials.value.profile.enabled_systems){
      return userCredentials.value.profile.enabled_systems[systemName]["enabled"]["value"]
    }
  
    return false
  } catch {
    return false
  }
}

const canAccessBankRecords = computed(() => {
  const result = accessCheck("bank_records")
  return result
});

const canAccessUserManagement = computed(() => {
  const result = accessCheck("user_management")
  return result
});

const canAccessCustomerCreation = computed(() => {
  const result = accessCheck("customer_creation")
  return result
});

const canAccessCrm = computed(() => {
  const result = accessCheck("crm")
  return result
});

const canAccessReceipts = computed(() => {
  const result = accessCheck("receipts")
  return result
});

const devEnv = computed (()=>{
  const runningEnv = process.env.NODE_ENV;
  if (runningEnv == "development") {
    return true
  } else {
    return false
  }
})

function goTo(pageName) {
  router.push({ name: pageName });
}
</script>

<style scoped>
.options-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row wrap;
}

.option-btn{
  width: 300px;
  height: 4rem;
  padding: 5px 8px;
}
</style>
