<template>
  <main-base title="Login" subTitle="Sistema Integrado de Cobrança">
    <template #content>
      <ion-card id="login-container">
        <ion-card-header>
          <ion-card-title>
            Informe suas credenciais para continuar
          </ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ion-item :class="{ 'ion-invalid': usernameHT }">
            <ion-label position="stacked" to="username">Usuário</ion-label>
            <ion-input id="username" name="username" placeholder="Usuário" v-model="username"></ion-input>
            <ion-note slot="error">{{ usernameHT }}</ion-note>
          </ion-item>
          <ion-item :class="{ 'ion-invalid': passwordHT }">
            <ion-label position="stacked" to="password">Senha</ion-label>
            <ion-input id="password" name="password" placeholder="Senha" type="password" v-model="password"></ion-input>
            <ion-note slot="error"> {{ passwordHT }} </ion-note>
          </ion-item>
          <ion-button id="btn-login" color="primary" @click.prevent="attemptLogin">Confirmar</ion-button>
          <ion-button id="btn-retrieve-password" color="light" @click="retrievePassword">Recuperar senha</ion-button>
        </ion-card-content>
      </ion-card>
    </template>
  </main-base>
</template>

<script setup>
import MainBase from "@/components/global_base/MainBase.vue";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonNote,
} from "@ionic/vue";
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useUx } from "@/hooks/userExperience.js";

const router = useRouter()
const store = useStore()
const { playToast } = useUx()

const username = ref("")
const password = ref("")
const usernameHT = ref(false) //username help text
const passwordHT = ref(false) //password help text

function retrievePassword() {
}

async function attemptLogin() {
  if (!validateData()) {
    return false
  }

  const payload = {
    username: username.value,
    password: password.value,
  };

  const result = await store.dispatch("auth/login", payload, {root: true})
  if (result.status == 200) {
    playToast("Bem vindo de volta!");
    router.replace({ name: "HomePage" });
  } else if (result.status == 401) {
    playToast("Dados de acesso inválidos!", "danger");
  } else {
    playToast("Servidor não responde", "danger");
  }
}

function validateData() {
  // Reset username and password help texts
  usernameHT.value = false;
  passwordHT.value = false;

  if (!usernameIsValid.value) {
    usernameHT.value = "Digite um nome de usuário válido.";
    return false
  }

  if (!passwordIsValid.value) {
    passwordHT.value = "A senha deve possuir pelo menos 8 caracteres.";
    return false
  }

  return true
}


const usernameIsValid = computed(() => {
    if (username.value && username.value.length > 3) {
      return true;
    } else {
      return false;
    }
  })

const passwordIsValid = computed(() => {
  if (password.value && password.value.length >= 8) {
    return true
  } else {
    return false
  }
})


watch(
  usernameIsValid,
  (newValue) => {
    if (newValue === false) {
      usernameHT.value = "Digite um nome de usuário válido.";
    } else {
      usernameHT.value = "";
    }
  }
)

</script>


<style scoped>
ion-card {
  width: 90%;
  padding: 50px 20px;
  margin: 20vh auto;
}

@media (max-width: 800px) {
  ion-card {
    width: 95%;
    /* height: 50%; */
    padding: 1vh 0px;
    margin: 1vh auto;
  }
}
</style>