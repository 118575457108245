<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
});
</script>

<style>
.loading-data-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ion-spinner {
  margin: 20px 0px;
}

.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-enter-from {
  opacity: 0;
}

div.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md{
    --width: 90% !important;
    --max-width: 90% !important;
  }

@media print {
  .ion-page {
    display: initial !important;
    position: initial !important;
  }

  body {
    position: initial !important;
    max-height: initial !important;
    overflow: auto !important;
  }

  ion-router-outlet {
    overflow: auto !important;
    contain: none !important;
    position: initial !important;
  }

  ion-content {
    --offset-bottom: unset !important;
    display: unset !important;
    ;
    position: unset !important;
    ;
  }
}</style>