import {
    toastController,
  } from "@ionic/vue";


export function useUx(){
    async function playToast(message, color = undefined) {
        const toast = await toastController.create({
          message: message,
          duration: 3000,
          position: "top",
          color: color,
        });
        await toast.present();
      }

      function clickToCopy(target){
        navigator.clipboard.writeText(target)
        const msg = `Valor copiado: ${target}`
        playToast(msg)
    }

      return {
        playToast: playToast,
        clickToCopy: clickToCopy,
      }
}