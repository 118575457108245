import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/modules/store.js';
import { checkUserCredentialsV2  } from "@/utils/customFunctions.js"

// Generic Views...
import HomePage from '@/views/others/HomePage.vue';
import TheLogin from '@/views/others/TheLogin.vue';
const PrepareToPrint = () => import('@/views/others/PrepareToPrint.vue');

// Receipt Views...
const CreateReceipt = () => import('@/views/receipts/CreateReceipt.vue');
const PendingReceipts = () => import('@/views/receipts/PendingReceipts.vue');
const ReceiptsByCustomer = () => import('@/views/receipts/ReceiptsByCustomer.vue');
const ReceiptsByDate = () => import('@/views/receipts/ReceiptsByDate.vue');
const ReceiptReports = () => import('@/views/receipts/ReceiptReports.vue');
const ReceiptsConciliatedByDate = () => import('@/views/receipts/ReceiptsConciliatedByDate.vue');
const DetailReceipt = () => import('@/views/receipts/DetailReceipt.vue');

// bank_records Views...
const NewBankRecord = () => import('@/views/bank_records/NewBankRecord.vue');
const CheckToReconcileBankRecords = () => import('@/views/bank_records/CheckToReconcileBankRecords.vue');
const BankRecordsByDate = () => import('@/views/bank_records/BankRecordsByDate.vue');

// UserManagement Views...
const TheUserManager = () => import('@/views/user_management/TheUserManager.vue');
const UpdateOwnPassword = () => import('@/views/user_management/UpdateOwnPassword.vue');

// CRM Views...
// - - - > Customer Creation...
const PrintableCustomerForm = () => import('@/views/customer_creation/PrintableCustomerForm.vue');
const PrintableEmptyCustomerForm = () => import('@/views/customer_creation/PrintableEmptyCustomerForm.vue');
const CreateCustomer = () => import('@/views/customer_creation/CreateCustomer.vue');
const CustomerDetailArchive = () => import('@/views/customer_creation/CustomerDetailArchive.vue');
const PreregistrationFollowup = () => import('@/views/customer_creation/PreregistrationFollowup.vue');
const PrintableSpokesmenList = () => import('@/views/customer_creation/PrintableSpokesmenList.vue');
const CustomerDocModels = () => import('@/views/customer_creation/CustomerDocModels.vue');
const PrintableEmptyAddressDeclaration = () => import('@/views/customer_creation/PrintableEmptyAddressDeclaration.vue');

// - - - > Relevant Interactions with Customer...
const CreateRelevantInteraction = () => import('@/views/crm_ri/CreateRelevantInteraction.vue');
const RelevantInteractionFollowup = () => import('@/views/crm_ri/RelevantInteractionFollowup.vue');
const RelevantInteractionCustomerArchive = () => import('@/views/crm_ri/RelevantInteractionCustomerArchive.vue');
const RelevantInteractionReports = () => import('@/views/crm_ri/RelevantInteractionReports.vue');
const RiReportKindAndStatus = () => import('@/views/crm_ri/RiReportKindAndStatus.vue');

const routes = [
  {
    path: '/',
    redirect: { name: 'HomePage' }
  },
  {
    path: '/login',
    name: 'Login',
    component: TheLogin,
    meta: {
      dontRequiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
  },

  // Receipts routes...
  {
    path: '/receipts',
    redirect: { name: 'NewReceipt' }
  },
  {
    path: '/receipts/report',
    name: 'Report',
    component: PrepareToPrint,
    meta: {
      requiredCredentials: {
        system: "receipts",
        permission: "enabled",
      }
    }
  },
  {
    path: '/receipts/new-receipt',
    name: 'NewReceipt',
    component: CreateReceipt,
    meta: {
      requiredCredentials: {
        system: "receipts",
        permission: "enabled",
      }
    }
  },
  {
    path: '/receipts/receipt-reports',
    name: 'ReceiptReports',
    component: ReceiptReports,
    meta: {
      requiredCredentials: {
        system: "receipts",
        permission: "show_reports",
      }
    }
  },
  {
    path: '/receipts/pending-receipts',
    name: 'PendingReceipts',
    component: PendingReceipts,
    meta: {
      requiredCredentials: {
        system: "receipts",
        permission: "enabled",
      }
    }
  },
  {
    path: '/receipts/receipts-by-customer/:customerId',
    name: 'ReceiptsByCustomer',
    component: ReceiptsByCustomer,
    props: true,
    meta: {
      requiredCredentials: {
        system: "receipts",
        permission: "show_reports",
      }
    }
  },
  {
    path: '/receipts/receipts-by-date/:receiptDate',
    name: 'ReceiptsByDate',
    component: ReceiptsByDate,
    props: true,
    meta: {
      requiredCredentials: {
        system: "receipts",
        permission: "show_reports",
      }
    }
  },
  {
    path: '/receipts/receipts-conciliated-at/:receiptDate',
    name: 'ReceiptsConciliatedByDate',
    component: ReceiptsConciliatedByDate,
    props: true,
    meta: {
      requiredCredentials: {
        system: "receipts",
        permission: "show_reports",
      }
    }
  },
  {
    path: '/receipts/detail/:receiptId',
    name: 'ReceiptDetail',
    component: DetailReceipt,
    props: true,
    meta: {
      requiredCredentials: {
        system: "receipts",
        permission: "show_reports",
      }
    }
  },

  //Bank records routes...
  {
    path: '/bank-records',
    redirect: { name: 'BankRecordsByDate' }
  },
  {
    path: '/bank-records/new',
    name: 'NewBankRecord',
    component: NewBankRecord,
    meta: {
      requiredCredentials: {
        // role: 'auxiliar administrativo',
        // allowedDepartments: ['cobranca', 'financeiro'],
        system: "bank_records",
        permission: "import_ofx",
      }
    }
  },
  {
    path: '/bank-records/list',
    name: 'BankRecordsByDate',
    component: BankRecordsByDate,
    meta: {
      requiredCredentials: {
        // role: 'auxiliar administrativo',
        // allowedDepartments: ['cobranca', 'financeiro'],
        system: "bank_records",
        permission: "enabled",
      }
    }
  },
  {
    path: '/bank-records/check-to-reconcile',
    name: 'CheckToReconcileBankRecords',
    component: CheckToReconcileBankRecords,
    meta: {
      requiredCredentials: {
        // role: 'auxiliar administrativo',
        // allowedDepartments: ['cobranca', 'financeiro'],
        system: "bank_records",
        permission: "check_to_reconcile",
      }
    }
  },

  //User Management routes...
  {
    path: '/system-management',
    redirect: { name: 'UserManagement' }
  },
  {
    path: '/system-management/users',
    name: 'UserManagement',
    component: TheUserManager,
    meta: {
      requiredCredentials: {
        // role: 'analista',
        // allowedDepartments: ['ti'],
        system: "user_management",
        permission: "enabled",
      }
    }
  },
  {
    path: '/system-management/change_own_password',
    name: 'UpdateOwnPassword',
    component: UpdateOwnPassword,
  },

  // CRM routes...
  // - - - > Create Customer routes...
  {
    path: '/crm',
    redirect: { name: 'CreateCustomer' }
  },
  {
    path: '/crm/create-customer',
    name: 'CreateCustomer',
    component: CreateCustomer,
    meta: {
      requiredCredentials: {
        system: "customer_creation",
        permission: "enabled",
      }
    }
  },
  {
    path: '/crm/follow-up',
    name: 'PreregistrationFollowup',
    component: PreregistrationFollowup,
    meta: {
      requiredCredentials: {
        system: "customer_creation",
        permission: "enabled",
      }
    }
  },
  {
    path: '/crm/archive/:targetCust',
    name: 'CustomerDetailArchiveProps',
    component: CustomerDetailArchive,
    props: true,
    meta: {
      requiredCredentials: {
        // role: 'auxiliar administrativo',
        // allowedDepartments: ['cobranca'],
        system: "customer_creation",
        permission: "view_archive",
      }
    }
  },
  {
    path: '/crm/archive',
    name: 'CustomerDetailArchive',
    component: CustomerDetailArchive,
    meta: {
      requiredCredentials: {
        // role: 'auxiliar administrativo',
        // allowedDepartments: ['cobranca'],
        system: "customer_creation",
        permission: "view_archive",
      }
    }
  },
  {
    path: '/crm/models/',
    name: 'CustomerDocModels',
    component: CustomerDocModels,
  },
  {
    path: '/crm/models/address-declaration/',
    name: 'PrintableEmptyAddressDeclaration',
    component: PrintableEmptyAddressDeclaration,
  },
  {
    path: '/crm/models/registration-form/',
    name: 'PrintableEmptyCustomerForm',
    component: PrintableEmptyCustomerForm,
  },
  {
    path: '/crm/models/registration-form/:cpfCnpj',
    name: 'PrintableCustomerForm',
    component: PrintableCustomerForm,
    props: true,
    meta: {
      requiredCredentials: {
        system: "customer_creation",
        permission: "enabled",
      }
    }
  },
  {
    path: '/crm/models/spokesmen-list/:cpfCnpj',
    name: 'PrintableSpokesmenList',
    component: PrintableSpokesmenList,
    props: true,
    meta: {
      requiredCredentials: {
        system: "customer_creation",
        permission: "enabled",
      }
    }
  },
  // - - - > CRM Requests routes...
  {
    path: '/crm/relevant-interaction',
    redirect: { name: 'CreateRelevantInteraction'}
  },
  {
    path: '/crm/relevant-interaction/create',
    name: 'CreateRelevantInteraction',
    component: CreateRelevantInteraction,
    meta: {
      requiredCredentials: {
        system: "crm",
        permission: "create_ri",
      }
    }
  },
  {
    path: '/crm/relevant-interaction/follow-up',
    name: 'RelevantInteractionFollowup',
    component: RelevantInteractionFollowup,
    meta: {
      requiredCredentials: {
        system: "crm",
        permission: "enabled",
      }
    }
  },
  {
    path: '/crm/relevant-interaction/archive',
    name: 'RelevantInteractionCustomerArchive',
    component: RelevantInteractionCustomerArchive,
    meta: {
      requiredCredentials: {
        system: "crm",
        permission: "view_archive",
      }
    }
  },
  {
    path: '/crm/relevant-interaction/reports',
    name: 'RelevantInteractionReports',
    component: RelevantInteractionReports,
    meta: {
      requiredCredentials: {
        system: "crm",
        permission: "show_reports",
      }
    }
  },
  {
    path: '/crm/relevant-interaction/reports/status/:targetStatus/kind/:targetKind',
    name: 'RiReportKindAndStatus',
    component: RiReportKindAndStatus,
    props: true,
    meta: {
      requiredCredentials: {
        system: "crm",
        permission: "show_reports",
      }
    }
  },
]

// Development environment utils
const runningEnv = process.env.NODE_ENV;
if (runningEnv == "development") {
  const TheDev = () => import('@/views/others/TheDev.vue');
  routes.push(
    {
      path: '/testando',
      name: 'Testando',
      component: TheDev,
    }
  )
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  const debug = false

  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const dontRequiresAuth = to.meta.dontRequiresAuth || false;
  const specialCredentialsRequired = to.meta.requiredCredentials || false;

  if (debug) {
    console.log('navigationGuards: to = ', to)
    console.log('isAuthenticated: ', isAuthenticated)
    console.log('dontRequiresAuth: ', dontRequiresAuth)
  }

  if (dontRequiresAuth) { //1st CONDITION
    if (debug) console.log('1st conditional')
    // Assessment:
    // + The desired page doesn't require authentication +
    // = Result: Allow user navigation
    return true
  }

  if (isAuthenticated) { //2nd CONDITIONN
    if (debug) console.log('2nd conditional')
    // Assessment:
    // + The desired page REQUIRES authentication (consequence of 1st Condition) + 
    // + The user IS Authenticated + 
    // + ...
    // + + Check if the desired page requires special credentials...
    // = = Result: false => Allow user navigation
    // = = Result: true => ...
    // + + + User has required credentials ? 
    // = = = Result: true => Allow user navigation
    // = = = Result: false => Don't allow user navigation

    if (to.name == "UpdateOwnPassword") {
      return true
    }

    const userCredentials = store.getters['auth/userCredentials'];

    if (debug) console.log(userCredentials)

    if (userCredentials['profile']['require_password_update']) {
      if (debug) {
        console.log("User must update password first: ", userCredentials['profile'])
      }
      return { name: 'UpdateOwnPassword' }
    }

    if (!specialCredentialsRequired) {
      return true
    } else {
      const allowNavigation = checkUserCredentialsV2(userCredentials, specialCredentialsRequired.system, specialCredentialsRequired.permission)
      return allowNavigation
    }
  }

  const validStoredAuthData = await store.dispatch('auth/validateStoredAuthData', null, { root: true })

  if (validStoredAuthData) { //3th CONDITIONN
    if (debug) console.log('3th conditional')
    // Assessment:
    // + The desired page REQUIRES authentication (consequence of 1st Condition) + 
    // + The user is NOT Authenticated + 
    // + There is valid auth data stored in IonicStorage +
    //      -> This situation occurs tipicaly when user refreshs the browser
    //      -> See more about in modules/state_rehydration.md
    // + ...
    // + + Check if the desired page requires special credentials...
    // = = Result: false => Allow user navigation
    // = = Result: true => ...
    // + + + User has required credentials ? 
    // = = = Result: true => Allow user navigation
    // = = = Result: false => Don't allow user navigation

    await store.dispatch('auth/rehydrateAuthData', null, { root: true })
    await store.dispatch("auth/setUserCredentials", null, { root: true })

    if (!specialCredentialsRequired) {
      return true
    } else {
      // await store.dispatch('auth/setUserCredentials', null, { root: true })
      const userCredentials = store.getters['auth/userCredentials'];
      const allowNavigation = checkUserCredentialsV2(userCredentials, specialCredentialsRequired.system, specialCredentialsRequired.permission)
      return allowNavigation
    }
  } else {
    if (debug) console.log('4th conditional')
    // Assessment:
    // + The desired page REQUIRES authentication (consequence of 1st Condition) + 
    // + The user is NOT Authenticated + 
    // + There is NO valid auth data stored in IonicStorage +
    // = Result: User must login again -> Redirect to Login's page.
    return { name: 'Login' }
  }
})

export default router