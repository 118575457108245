const visiting_days = [
  { labelPTBR: 'SEG', labelEn: 'MON', id: 1, labelUser: 'SEG' },
  { labelPTBR: 'TER', labelEn: 'TUE', id: 2, labelUser: 'TER' },
  { labelPTBR: 'QUA', labelEn: 'WED', id: 3, labelUser: 'QUA' },
  { labelPTBR: 'QUI', labelEn: 'THR', id: 4, labelUser: 'QUI' },
  { labelPTBR: 'SEX', labelEn: 'FRI', id: 5, labelUser: 'SEX' },
  { labelPTBR: 'SEGQUI', labelEn: 'MON_THR', id: 6, labelUser: 'SEG, QUI' },
  { labelPTBR: 'TERSEX', labelEn: 'TUE_FRI', id: 7, labelUser: 'TER, SEX' },
  { labelPTBR: 'INDEFINIDO', labelEn: 'UNDEFINED', id: 99, labelUser: 'N/D' },
]

function formatCPForCNPJ(str) {
  const normStr = String(str)
  if (normStr.length == 11) {
    // str is CPF
    const formatedCPF = normStr.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    return formatedCPF
  } else if (normStr.length == 14) {
    // str is CNPJ
    const formatedCNPJ = normStr.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    return formatedCNPJ
  } else {
    // str is not CPF nor CNPJ
    return str
  }
}

function formatDateAsString(selectedDate, customOptions = false) {
  let convertedDate = selectedDate

  if (typeof selectedDate == 'string') {
    convertedDate = new Date(selectedDate)
  }

  let options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }

  if (customOptions) {
    options = customOptions
  }


  const result = convertedDate.toLocaleString("pt-BR", options);

  return result
}

function formatAsCurrency(value_str) {
  const result = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value_str)

  return result
}

function containsNumber(pass) {
  let result = false
  for (let i = 0; i < 10; i++)
    if (pass.search(i) != -1) {
      result = true;
      break;
    }
  return result
}

function containsLowerCase(pass) {
  const lowerCaseLetters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
  ]
  let result = false;
  for (let i = 0; i < lowerCaseLetters.length; i++) {
    if (pass.search(lowerCaseLetters[i]) != -1) {
      result = true;
      break;
    }
  }
  return result;
}

function containsUpperCase(pass) {
  const upperCaseLetters = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ]
  let result = false;
  for (let i = 0; i < upperCaseLetters.length; i++) {
    if (pass.search(upperCaseLetters[i]) != -1) {
      result = true;
      break;
    }
  }
  return result;
}

function _TestaCPF(val) {
  if (val.length < 13) {
    let cpf = val.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (let i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = ((v1 * 10) % 11);

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (let i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = ((v2 * 10) % 11);

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false
  }
}

function _TestaCpfCnpj(val) {
  // Function inherited from https://irias.com.br/blog/como-validar-cpf-cnpj-em-node-js/

  if (val.length < 13) {
    let cpf = val.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (let i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = ((v1 * 10) % 11);

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (let i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = ((v2 * 10) % 11);

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val.length > 13) {
    let cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.split('');

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (let i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = (v1 % 11);

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = (11 - v1);
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (let i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = (v2 % 11);

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = (11 - v2);
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function validateCPF(input) {
  if (_TestaCPF(input)) {
    return true;
  } else {
    return false;
  }
}

function validateCPForCNPJ(input) {
  return _TestaCpfCnpj(input)
}

function validateName(input) {
  const trimmedInput = input.trim()
  if (trimmedInput.length > 12 && !containsNumber(trimmedInput)) {
    return true;
  } else {
    return false;
  }
}

function validateVendorCode(input) {
  const str_inp = String(input)
  if (str_inp.length == 3) {
    return true;
  } else {
    return false;
  }
}

function validateCustomerEnterpriseCode(input) {
  const str_inp = String(input)
  if (str_inp.length >= 4 && str_inp.length <= 9) {
    return true;
  } else {
    return false;
  }
}

function validateBirthDate(input) {
  try {
    const givenDate = new Date(input + "T00:00:00Z");
    const minDate = new Date("1930-01-01T00:00:00Z");
    const result = givenDate > minDate;
    return result;
  } catch (e) {
    return false;
  }
}

function validatePhone(input) {
  if (input.length >= 8 && input.length <= 9) {
    return true;
  } else {
    return false;
  }
}

function validateDDD(input) {
  if (input.length == 3) {
    return true;
  } else {
    return false;
  }
}

// function validateEmail(input) {
  // if (input.length > 3 &&
  //   input.search("@") != -1 &&
  //   input.search(".com") != -1) {
  //   return true;
  // } else {
  //   return false;
  // }
// }

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

// Validates user credentials based in ROLE and DEPARTMENT
// DEPRECATED 2023-08-10 (v6_002): Use checkUserCredentialsV2 insted
// eslint-disable-next-line
function checkUserCredentials(userCredentials, minumumUserCredentials) {

  // Add's the 'diretoria' to the allowed departments
  let allowedDepts = minumumUserCredentials.allowedDepartments
  allowedDepts.unshift('diretoria')

  // Checks if the user belongs to a allowed department
  if (!allowedDepts.includes(userCredentials.profile.department)) {
    return false
  }

  // Relation of all possible user roles and its credential rating
  const userRoles = new Map(
    [
      ['vendedor', 1],
      ['supervisor', 3],
      ['gerente', 5],
      ['auxiliar administrativo', 2],
      ['analista', 2],
      ['diretor', 10],
    ]
  )

  // Calculates the user credential rating
  var userRating = 0
  if (userRoles.has(userCredentials.profile.role)) {
    userRating = userRoles.get(userCredentials.profile.role)
  }

  // Calculates the minimum required user credential rating
  const minumumUserRoleRating = userRoles.get(minumumUserCredentials.role)

  // Checks if the user credential rating is equal or bigger than the required one
  return userRating >= minumumUserRoleRating
}

function checkUserCredentialsV2(userCredentials, requiredSystem, requiredPermission) {
  try {
    const result = (
      (userCredentials.profile.enabled_systems[requiredSystem]["enabled"]["value"] == true) &&
      (userCredentials.profile.enabled_systems[requiredSystem][requiredPermission]["value"] == true)
    )
    return result
  } catch {
    return false
  }
}

function convertVisitingDayToString(vsInt) {

  const vsStr = visiting_days.filter((element) => {
    return element.id == vsInt
  })

  if (vsStr) {
    return vsStr.labelUser
  } else {
    return "Indefinido"
  }

}

function convertVisitingDayToInt(input) {
  let vsStr = input

  vsStr = vsStr.replace(" ", "")
  vsStr = vsStr.replace(",", "")

  const vsInt = visiting_days.find((element) => {
    return vsStr.localeCompare(element.labelPTBR, undefined, { sensitivity: "base" }) == 0
  })

  if (vsInt) {
    return vsInt.id
  } else {
    return false
  }
}

export {
  formatAsCurrency, formatDateAsString, containsNumber, containsLowerCase, containsUpperCase,
  validateBirthDate, validateCPF, validateName, validatePhone, validateDDD,
  validateCPForCNPJ, validateVendorCode, validateCustomerEnterpriseCode, formatCPForCNPJ, checkUserCredentialsV2, 
  validateEmail, convertVisitingDayToInt, convertVisitingDayToString,
}