import { createStore } from 'vuex'
import authModule from './auth.js'
import receiptsModule from './receipts.js';
import bankRecordsModule from './bankRecords.js';
import crmModule from './crm.js';
import ionicStorage from '../ionic_storage/ionicStorage.js';
import { RequestHandler } from '../utils/customHandlers';

// Create a new store instance.
const store = createStore({
    modules: {
        auth: authModule,
        receipts: receiptsModule,
        bankRecords: bankRecordsModule,
        crm: crmModule
    },

    state() {
        return {
            count: 0,
            loading: false,
            reportData: false,  // Related to printing reports functionality (ReportPrint.vue)
            reportColumnsToPrint: false,  // Related to printing reports functionality (ReportPrint.vue)
            reportTitle: false,  // Related to printing reports functionality (ReportPrint.vue)
        }
    },

    getters:{
        loading(st){
            return st.loading
        },
        reportData(st){
            return st.reportData
        },
        reportColumnsToPrint(st){
            return st.reportColumnsToPrint
        },
        reportTitle(st){
            return st.reportTitle
        }
    },

    actions: {
        async getPaginatedData(context, payload) {
            const rH = new RequestHandler(context)
            const targetUrl = payload
            const response = await rH.getTargetNoLoading(targetUrl)
            return response
        },
    },
    
    mutations: {
        toggleLoading(st, payload){

            // st.loading = payload

            if (payload===false) {
                setTimeout(() => {
                    st.loading = payload        
                }, 700);
            } else {
                st.loading = payload
            }
        },
        reportData(st, payload){
            st.reportData = payload
        },
        reportColumnsToPrint(st, payload){
            st.reportColumnsToPrint = payload
        },
        reportTitle(st, payload){
            st.reportTitle = payload
        }
    }
})


store.subscribeAction( async ( action )=>{
    if (action.type === 'auth/setAuthData') {
        const authData = {
            access: action.payload.access,
            refresh: action.payload.refresh || 'invalid-refresh-token'
        }

        const jsonAuthData = JSON.stringify(authData)

        await ionicStorage.set('jsonAuthData', jsonAuthData)
            // .then( async ()=>{
            // })
    }
})

// store.subscribe((mutation)=>{
//     if (mutation.type == "auth/setAuthData"){
//         store.dispatch("auth/setUserCredentials")
//     }
// })

export default store