import { RequestHandler } from '../utils/customHandlers';

const crmModule = {
    namespaced: true,
    state() {
        return {
            visiting_days: [
                { label: 'MON', id: 1, labelUser: 'SEG' },
                { label: 'TUE', id: 2, labelUser: 'TER' },
                { label: 'WED', id: 3, labelUser: 'QUA' },
                { label: 'THR', id: 4, labelUser: 'QUI' },
                { label: 'FRI', id: 5, labelUser: 'SEX' },
                { label: 'MON_THR', id: 6, labelUser: 'SEG, QUI' },
                { label: 'TUE_FRI', id: 7, labelUser: 'TER, SEX' },
                { label: 'UNDEFINED', id: 99, labelUser: 'N/D' },
            ],
            doc_status: [
                { label: 'APPROVED', id: 1, labelUser: "Aprovado" },
                { label: 'UNDER_REVIEW', id: 2, labelUser: "Em revisão pelo comercial" },
                { label: 'REJECTED', id: 3, labelUser: "Recusado" },
                { label: 'PENDING', id: 4, labelUser: "Em análise pela cobrança" },
                { label: 'DEPRECATED', id: 5, labelUser: "Desatualizado" },
                { label: 'DISCARDED', id: 6, labelUser: "Descartado" },
            ],
            doc_kind: [
                { id: 1, label: 'ID_PERSON_FRONT', labelUser: "Frente do documento de identificação do cliente (Pessoa Física)" },
                { id: 2, label: 'ID_PERSON_BACK', labelUser: "Verso do documento de identificação do cliente (Pessoa Física)" },
                { id: 3, label: 'ID_PARTNER_FRONT', labelUser: "Frente do documento de identificação de sócio (Pessoa Jurídica)" },
                { id: 4, label: 'ID_PARTNER_BACK', labelUser: "Verso do documento de identificação de sócio (Pessoa Jurídica)" },
                { id: 5, label: 'ADDRESS_PERSON', labelUser: "Comprovante de residência" },
                { id: 6, label: 'ADDRESS_ENTERPRISE', labelUser: "Comprovante de residência da empresa" },
                { id: 7, label: 'ID_SPOKESMAN_FRONT', labelUser: "Frente do documento de identificação de pessoa autorizada a comprar (preposto)" },
                { id: 8, label: 'ID_SPOKESMAN_BACK', labelUser: "Verso do documento de identificação de pessoa autorizada a comprar (preposto)" },
                { id: 9, label: 'SPOKESMAN_LIST', labelUser: "Relação de pessoas autorizadas a comprar" },
                { id: 10, label: 'REGISTRATION_FORM', labelUser: "Ficha cadastral preenchida e assinada" },
                { id: 11, label: 'BUILDING_FACADE', labelUser: "Foto da fachada do PDV com GPS" },
                { id: 12, label: 'ARTICLES_OF_ASSOCIATION', labelUser: "Contrato social" },
                { id: 13, label: 'SEFAZ_INQUIRY', labelUser: "Consulta a SEFAZ (inscrição estadual)" },
                { id: 14, label: 'CREDIT_INQUIRY', labelUser: "Consulta ao SPC/Serasa" },
                { id: 15, label: 'ADDRESS_DECLARATION', labelUser: "Termo de declaração de endereço" },
                { id: 16, label: 'CPF_REGISTRATION_STATUS', labelUser: "Comprovante de Situação Cadastral no CPF" },
                { id: 17, label: 'LENDING_CONTRACT', labelUser: "Contrato de Comodato" }
            ],
            doc_classes: [
                { id: 1, labelUser: "Documento de identificação", value: [1, 2, 3, 4, 7, 8, 16] },
                { id: 2, labelUser: "Comprovante de endereço", value: [5, 6, 15] },
                { id: 3, labelUser: "Lista de prepostos", value: 9 },
                { id: 4, labelUser: "Ficha cadastral", value: 10 },
                { id: 5, labelUser: "Foto da fachada", value: 11 },
                { id: 6, labelUser: "Contrato social", value: 12 },
                { id: 7, labelUser: "Consultas a SEFAZ", value: 13 },
                { id: 8, labelUser: "Consultas ao SPC/Serasa", value: 14 },
                { id: 9, labelUser: "Comodatos", value: 17 },
            ],
            ri_status: [
                { level: 'parent', id: 1, label: 'IN_PROGRESS', labelUser: 'Em andamento' },
                { level: 'parent', id: 2, label: 'DONE', labelUser: 'Concluído' },
                { level: 'off', id: 3, label: 'DEPRECATED', labelUser: 'Desatualizado' },
                { level: 'off', id: 4, label: 'APPROVED', labelUser: 'Aprovado' },
                { level: 'off', id: 5, label: 'REPROVED', labelUser: 'Reprovado' },
            ],
            ri_kind: [
                // # Custom / Other = 1
                {level: 'off', label: 'IS_CUSTOM', id: 100, labelUser: 'Outros'},
                {level: 'off', label: 'ADVERSE_PHENOMENON', id: 101, labelUser: 'Fenômenos adversos'},
                
                // # Commercial
                {level: 'off', label: 'BUSINESS_EXCLUSIVITY', id: 200, labelUser: 'Exclusividade comercial'},
                {level: 'off', label: 'EVENT_REALIZATION', id: 201, labelUser: 'Realização de evento'},
                {level: 'parent', label: 'LENDING_REQUEST', id: 202, labelUser: 'Solicitação de comodato'},  // ok  -> parent
                {level: 'parent', label: 'TEMPORARY_LENDING_REQUEST', id: 203, labelUser: 'Solicitação de comodato temporário'}, // ok  -> parent
                {level: 'parent', label: 'LENDING_DEVOLUTION', id: 204, labelUser: 'Solicitação de recolhimento de comodato'}, // ok  -> parent
                {level: 'child', label: 'LENDING_CONFIRMATION', id: 205, labelUser: 'Confirmação de entrega'}, // ok  -> parent
                {level: 'child', label: 'LENDING_DEVOLUTION_CONFIRMATION', id: 206, labelUser: 'Confirmação de recolhimento'}, // ok  -> parent
                
                // # Billing
                {level: 'off', label: 'CREDIT_DEFAULT', id: 300, labelUser: 'Inadimplência'},
                {level: 'parent', label: 'CREDIT_UPGRADE', id: 301, labelUser: 'Aumento de crédito'},    //ok -> parent
                {level: 'off', label: 'CREDIT_LIMIT_EXCEEDED', id: 302, labelUser: 'Limite de crédito excedido'},
                {level: 'off', label: 'PAID_BY_DEPOSIT', id: 303, labelUser: 'Pagamento de débito por depósito'},
                {level: 'parent', label: 'CREDIT_OPENING', id: 304, labelUser: 'Concessão de crédito'},  // ok -> parent
                {level: 'child', label: 'CREDIT_ANALYSIS', id: 305, labelUser: 'Análise de crédito'},   //ok 
                {level: 'parent', label: 'TEMPORARY_CREDIT_UPGRADE', id: 306, labelUser: 'Aumento de crédito temporário'},   //ok -> parent
                
                // # Shipping
                {level: 'off', label: 'REJECTED_PURCHASE', id: 400, labelUser: 'Recusa de recebimento de pedido'},
                {level: 'off', label: 'REJECTED_LENDING_DEVOLUTION', id: 401, labelUser: 'Recusa de devolução de comodato'},
        
                // # Meta-kind
                {level: 'child', label: 'APPROVAL_OR_REPROVAL', id: 500, labelUser: 'Aprovação'},   // ok
                {level: 'child', label: 'TAKE_NOTICE', id: 501, labelUser: 'Ciente'},   //  ok -> default
                {level: 'child', label: 'IS_COMMENTARY', id: 502, labelUser: 'Comentário'}, //ok -> default
                {level: 'child', label: 'CLOSE_RI', id: 503, labelUser: 'Encerramento'}, //ok -> default
            ],
            lendable_items_list: false,
            products: false,
            addresses: false,
        }
    },
    getters: {
        getDocStatus(state) {
            return state.doc_status
        },
        getDocClasses(state) {
            return state.doc_classes
        },
        getDocStatusLabel: (state) => (id) => {
            const result = state.doc_status.find((el) => {
                return el.id == id
            })
            return result
        },
        getVisitingDayLabel: (state) => (id) => {
            const result = state.visiting_days.find((el) => {
                return el.id == id
            })
            return result
        },
        getDocKinds(state) {
            return state.doc_kind
        },
        getDocKindLabel: (state) => (id) => {
            const result = state.doc_kind.find((el) => {
                return el.id == id
            })
            return result
        },
        getRIKindLabel: (state) => (id) => {
            const result = state.ri_kind.find((el) => {
                return el.id == id
            })
            return result
        },
        getRIKinds(state){
            return state.ri_kind
        },
        getRIStatus(state){
            return state.ri_status
        },
        getParentRIKinds(state){
            const parentRiKinds = state.ri_kind.filter((item) => item.level == 'parent')
            return parentRiKinds
        },
        getParentRIStatus(state){
            const parentRiStatus = state.ri_status.filter((item) => item.level == 'parent')
            return parentRiStatus
        },
        getRIStatusLabel: (state) => (id) => {
            const result = state.ri_status.find((el) => {
                return el.id == id
            })
            return result
        },
        getOppositeKind: () => (id) => {
            const oppositeKinds = {
                1: 2,
                2: 1,
                3: 4,
                4: 3,
                7: 8,
                8: 7
            }
            return oppositeKinds[id]
        },
        getProductList(state){
            return state.products
        },
        getAddressList(state){
            return state.addresses
        },
        getLendableItemsList(state){
            return state.lendable_items_list
        }
    },
    actions: {
        async setLendableItems(context) {
            if (context.getters['getLendableItemsList']) {
                return context.getters['getLendableItemsList']
            } else {
                const targetUrl = 'api/crm/parameters/lendable_items/'
                const rH = new RequestHandler(context)
                const response = await rH.getTarget(targetUrl)

                if (response.status == 200) {
                    context.commit('setLendableItems', response.data)
                    return response.data
                } else {
                    return []
                }
    
            }
        },
        async getPreregistrationContacts(context, payload) {    // OK
            const target = payload['cpf_cnpj']
            const targetUrl = `api/crm/contacts/${target}/preregistration`
            const rH = new RequestHandler(context)
            const response = await rH.getTarget(targetUrl)

            return response
        },
        async getPrintableCustomerForm(context, payload) {
            const target = payload['cpf_cnpj']
            const targetUrl = `api/etl/cliente/${target}/registration-form`
            const rH = new RequestHandler(context)
            const response = await rH.getTarget(targetUrl)

            return response
        },
        async getSpokesmanListToPrint(context, payload) {   // OK
            const target = payload['cpf_cnpj']
            const targetUrl = `api/crm/spokesman/${target}/print_list`
            const rH = new RequestHandler(context)
            const response = await rH.getTarget(targetUrl)

            return response
        },
        async updateDeprecatedCustomerDoc(context, payload) {         // OK
            const parent_id = payload.get("parent_id")
            const targetUrl = `api/crm/customerdoc/${parent_id}/update_deprecated_doc/`
            const rH = new RequestHandler(context)
            const response = await rH.updateTarget(targetUrl, payload)
            return response
        },
        async getCustomerDocImage(context, payload) {   // OK
            // This action works together with confidentialFiles.js hook methods

            const imageId = payload['imageId'] || 0
            const callbackFn = payload['callbackFn']
            const notImageFile = payload['notImageFile'] || false

            // Handler config and server interaction
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/customerdoc/${imageId}/doc_img/`
            const response = await rH.getTargetImage(targetUrl)

            if (response.status != 200) {
                return false
            }

            if (notImageFile) {
                // If it's not image file:
                // Build and activate a link do download the document via
                // getFile() method
                callbackFn(response.data)
                return true
            }

            // Response handling, in order to output the image as a ready-to-use DataURL
            const reader = new FileReader()
            const myPromise = new Promise((resolve) => {
                reader.onload = (fileEvent) => {    // set's the onload behaviour of our new reader
                    callbackFn(fileEvent)   // First we execute the callback function
                    resolve(true)   // Then we resolve the promisse
                }
                reader.readAsDataURL(response.data)     // call's the reader readAsDataURL method, which will call the previous set onload method
            })

            return myPromise

        },
        async updateSpokesman(context, payload) {       // OK
            const targetUrl = `api/crm/spokesman/${payload.id}/`
            const rH = new RequestHandler(context)
            const response = await rH.updateTarget(targetUrl, payload)
            return response
        },
        async inactivateSpokesman(context, payload) {       // OK
            const targetUrl = `api/crm/spokesman/${payload.id}/inactivate/`
            const rH = new RequestHandler(context)
            const response = await rH.updateTarget(targetUrl, payload)
            return response
        },
        async getSpokesmanFollowUp(context, payload) {      // OK
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/spokesman/${payload}/follow_up/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getPreregistrationFollowUp(context) {     // OK
            context.commit("toggleLoading", 0, { root: true })

            const rH = new RequestHandler(context)
            const targetUrl = 'api/crm/preregistration/'
            const response = await rH.getTarget(targetUrl)

            context.commit("toggleLoading", 0, { root: true })
            return response
        },
        async getCustomerArchive(context, payload) {    // OK
            const target = payload['cpf_cnpj'] || payload['customer_enterprise_code'] || 0
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/customer/${target}/archive`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async checkIfCustomerExists(context, payload) {
            // Checks if there is a Customer with the provided CPF/CNPJ, Customer Code, Customer/Enterprise Code or BW Code
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/check-if-exists/customer/${payload}/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async checkIfCustomerOrPreregExists(context, payload) {
            // Checks if there is a Customer or a Preregistration with the provided CPF/CNPJ, Customer Code, Customer/Enterprise Code or BW Code
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/check-if-exists/customer-or-prereg/${payload}/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async createPreregistrationInfo(context, payload) {     // OK
            const rH = new RequestHandler(context)
            const targetUrl = 'api/crm/preregistration/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async createContactInfo(context, payload) {     // OK
            const rH = new RequestHandler(context)
            const targetUrl = 'api/crm/contacts/create'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async createSpokesmanInfo(context, payload) {       // OK
            const rH = new RequestHandler(context)
            const targetUrl = 'api/crm/spokesman/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async updateDocInfo(context, payload) {     // OK
            const targetUrl = `api/crm/customerdoc/${payload.id}/`

            const rH = new RequestHandler(context)
            const response = await rH.updateTarget(targetUrl, payload)
            return response
        },
        async createOneSideDoc(context, payload) {      // OK
            const targetUrl = 'api/crm/customerdoc/'
            const token = context.rootGetters['auth/getToken']
            const config = {
                headers: {
                    Authorization: 'Bearer ' + token,
                    "Content-Type": 'multipart/form-data',
                }
            }

            const rH = new RequestHandler(context)
            const response = await rH.createTarget(targetUrl, payload, config)
            return response
        },
        async verifyPreregistrationLog(context, payload) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/preregistration/${payload}/verify/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async createTwoSideDoc(context, payload) {
            if (Object.prototype.hasOwnProperty.call(payload, "back")) {
                // Create a CustomerDoc for back image
                const backImgResponse = await context.dispatch("createOneSideDoc", payload.back)

                // Create a CustomerDoc for front image, vinculating it's opposite_side_document attr with
                // back image's id
                const frontSidePayload = payload.front

                if (frontSidePayload instanceof FormData) {
                    frontSidePayload.append("opposite_side_document", backImgResponse.data.id)
                } else {
                    frontSidePayload["opposite_side_document"] = backImgResponse.data.id
                }

                const frontImgResponse = await context.dispatch("createOneSideDoc", frontSidePayload)

                // Create a log for back image's CustomerDoc
                if (Object.prototype.hasOwnProperty.call(payload, "backLog") && payload.backLog) {
                    const logPayload = {
                        customer_doc: backImgResponse.data.id,
                        log: payload.backLog
                    }
                    await context.dispatch("createDocLog", logPayload)
                }

                // Create a log for front image's CustomerDoc
                if (Object.prototype.hasOwnProperty.call(payload, "frontLog") && payload.frontLog) {
                    const logPayload = {
                        customer_doc: frontImgResponse.data.id,
                        log: payload.frontLog
                    }
                    await context.dispatch("createDocLog", logPayload)
                }

                // Vinculate the back image's opposite_side_document to front image's id
                const backSidePayload = {
                    id: backImgResponse.data.id,
                    opposite_side_document: frontImgResponse.data.id
                }

                await context.dispatch("updateDocInfo", backSidePayload)
                return frontImgResponse
            } else {
                return false
            }
        },
        async createDocLog(context, payload) {  // OK
            const rH = new RequestHandler(context)
            const targetUrl = 'api/crm/customerdoclogs/create'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },

        async createParentRI(context, payload) {  // OK
            const rH = new RequestHandler(context)
            const targetUrl = 'api/crm/ri/create/'
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async updateChildRI(context, payload) {  // OK
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/ri/${payload.id}/update/`
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async createChildRI(context, payload) {  // OK
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/ri/${payload.parent_interaction_id}/child/`
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async getBeforeCreateRiAuthorization(context, payload) {    // OK
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/ri/${payload}/before-create-check/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getCustomerPaymentHistory(context, payload) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/etl/cliente/${payload}/payment-history`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getRIsByCustomer(context, payload) {    // OK
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/ri/${payload}/all/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getRIsInProgress(context) {    // OK
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/ri/in_progress/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async haltPreregistration(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/preregistration/${payload.cpf_cnpj}/halt/`
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async getRIsByKindAndStatus(context, payload){
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/ri/by_kind_and_status/`
            const response = await rH.createTarget(targetUrl, payload)
            return response
        },
        async getDocsByCustomer(context, payload) {    // OK
            // payload = Customer's cpf_cnpj
            // Return docs with the following status:
            //      APPROVED = 1
            //      UNDER_REVIEW = 2
            //      PENDING = 4
            const rH = new RequestHandler(context)
            const targetUrl = `api/crm/customerdoc/${payload}/docs_by_customer/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async setProductList(context) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/etl/produtos/all/`
            const response = await rH.getTarget(targetUrl)
            
            if (response.status == 200) {
                context.commit("setProductList", response.data)
            }
        },
        async setAddressList(context) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/etl/enderecos/all/`
            const response = await rH.getTarget(targetUrl)
            
            if (response.status == 200) {
                context.commit("setAddressList", response.data)
            }
        }
    },
    mutations: {
        setProductList(state, payload) {
            state.products = payload
        },
        setAddressList(state, payload) {
            state.addresses = payload
        },
        setLendableItems(state, payload) {
            state.lendable_items_list = payload
        }
    }
}

export default crmModule 