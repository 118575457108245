import { RequestHandler } from '../utils/customHandlers';

const receiptsModule = {
    namespaced: true,
    state() {
        return {

        }
    },
    actions: {
        async getReceiptImage( context, payload ){
            const imageId = payload['imageId'] || 0
            const callbackFn = payload['callbackFn']

            // Handler config and server interaction
            const rH = new RequestHandler(context)
            const targetUrl = `/api/receipts/${imageId}/receipt_img/`
            const response = await rH.getTargetImage(targetUrl)

            if (response.status != 200){
                return false
            }

            // Response handling, in order to output the image as a ready-to-use DataURL
            const reader = new FileReader()
            const myPromise = new Promise((resolve)=>{
                reader.onload = (fileEvent) => {    // set's the onload behaviour of our new reader
                    callbackFn(fileEvent)   // First we execute the callback function
                    resolve(true)   // Then we resolve the promisse
                }
                reader.readAsDataURL(response.data)     // call's the reader readAsDataURL method, which will call the previous set onload method
            })
            
            return myPromise

        },
        async createReceiptLog(context, payload) {

            const targetUrl = 'api/receipt-logs/'

            const data = {
                "receipt": payload.receiptId,
                "log": payload.log
            }

            const rH = new RequestHandler(context)
            const response = await rH.createTarget(targetUrl, data)
            return response
        },
        async invalidateReceiptLog(context, logId) {

            const targetUrl = `api/receipt-logs/${logId}/`
            const data = {
                "valid": false
            }
            
            const rH = new RequestHandler(context)
            const response = await rH.updateTarget(targetUrl, data)
            return response

        },
        async getReceiptDetail(context, payload) {
            let receiptId
            try {
                receiptId = parseInt(payload)
            } catch (e) {
                const exception = {"status": 400, "message": "Invalid receipt id: it must be a number"}
                return exception
            }

            const rH = new RequestHandler(context)
            const targetUrl = `api/receipts/${receiptId}`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getReceiptsByCustomer(context, customerId) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/receipts/${customerId}/receipts_by_customer/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getReceiptCount(context, customerId) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/receipts/${customerId}/count/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getReceiptsConciliatedByDate(context, receiptDate) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/receipts/${receiptDate}/conciliated_at/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getReceiptsByDate(context, receiptDate) {
            const rH = new RequestHandler(context)
            const targetUrl = `api/receipts/${receiptDate}/receipts_by_date/`
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async getPendingReceipts(context) {
            const rH = new RequestHandler(context)
            const targetUrl = 'api/receipts/pending/'
            const response = await rH.getTarget(targetUrl)
            return response
        },
        async updateReceipt(context, payload) {

            const receiptId = payload.receiptId
            const targetUrl = `api/receipts/${receiptId}/`
            const token = context.rootGetters['auth/getToken']
            const config = {
                headers: {
                    Authorization: 'Bearer ' + token,
                    "Content-Type": 'multipart/form-data',
                }
            }
            const data = {
                ...payload
            }

            const rH = new RequestHandler(context)
            const response = await rH.updateTarget(targetUrl, data, config)
            return response

        },
        async createNewReceipt(context, payload) {

            const token = context.rootGetters['auth/getToken']
            const targetUrl = 'api/receipts/'
            const config = {
                headers: {
                    Authorization: 'Bearer ' + token,
                    "Content-Type": 'multipart/form-data',
                }
            }

            const rH = new RequestHandler(context)
            const response = await rH.createTarget(targetUrl, payload, config)
            return response

        },
        async checkCustomerId(context, payload) {
            const rH = new RequestHandler(context)
            const targetUrl = 'api/etl/customer/' + payload
            const response = await rH.getTarget(targetUrl)
            return response
        }
    }

}

export default receiptsModule 