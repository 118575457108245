import axios from 'axios';

function RequestHandler(context) {
    const { dispatch, getters, rootGetters, commit } = context;
    this.dispatch = dispatch;
    this.getters = getters;
    this.rootGetters = rootGetters;
    this.commit = commit;
}

RequestHandler.prototype = Object.create(Object.prototype, {
    constructor: {
        value: RequestHandler,
        configurable: false,
        writable: false,
        enumerable: false
    }
})

RequestHandler.prototype.getConfig = function () {
    const token = this.rootGetters['auth/getToken']
    const config = {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    return config
}

RequestHandler.prototype.dismissVerification = function () {
    //  Checks if it's necessary to verify the token validity again
    //      true: dismiss verification. Token is still ok and doesn't need a new verification.
    //      false: can't dismiss verification. Token must be verified again before attempt request to server.
    const now = new Date()
    const tokenTimestamp = this.rootGetters['auth/getTokenTimestamp']
    return (
        tokenTimestamp &&
        now > tokenTimestamp &&
        (now - tokenTimestamp) < (1000 * 60 * 10)   //10 minutes
    )
}

RequestHandler.prototype.verifyToken = async function () {
    //  Handle the process of token verification upon the server.
    //  Note: all the logic concearning the processes of verification, refresh or logout
    //        is executed inside auth module.
    //        * starter action -> checkTokenIsValid *
    if (this.dismissVerification()){
        return true
    } else {
        const result = await this.dispatch("auth/checkTokenIsValid", null, { root: true })
        return result        
    }
}

RequestHandler.prototype.getTarget = async function (targetUrl) {
    //  Handle the process of retrieving data from the server

    const validToken = await this.verifyToken()
    if (!validToken) {
        const exception = { "status": 404 }
        return exception
    }

    this.commit('toggleLoading', true, { root: true })

    return axios.get(targetUrl, this.getConfig()).then(
        (result) => {
            this.commit('toggleLoading', false, { root: true })
            return result
        }
    ).catch(
        (er) => {
            this.commit('toggleLoading', false, { root: true })
            if ('response' in er){
                return er.response
            }
            return { error: er, errorData: er.data }
        }
    )
}

RequestHandler.prototype.getTargetImage = async function (targetUrl) {
    //  Handle the process of retrieving data from the server

    const validToken = await this.verifyToken()
    if (!validToken) {
        const exception = { "status": 404 }
        return exception
    }

    let config = this.getConfig()
    config['responseType'] = "blob"

    return axios.get(targetUrl, config).then(
        (result) => {
            this.commit('toggleLoading', false, { root: true })
            return result
        }
    ).catch(
        (er) => {
            if ('response' in er){
                return er.response
            }
            return { error: er, errorData: er.data }
        }
    )
}

RequestHandler.prototype.getTargetNoLoading = async function (targetUrl) {
    //  Handle the process of retrieving data from the server

    const validToken = await this.verifyToken()
    if (!validToken) {
        const exception = { "status": 404 }
        return exception
    }

    return axios.get(targetUrl, this.getConfig()).then(
        (result) => {
            return result
        }
    ).catch(
        (er) => {
            if ('response' in er){
                return er.response
            }
            return { error: er, errorData: er.data }
        }
    )
}

RequestHandler.prototype.updateTarget = async function (targetUrl, payload, configParam = false){
    //  Handle the process of updating an item in the server
    const validToken = await this.verifyToken()
    if (!validToken) {
        const exception = { "status": 404 }
        return exception
    }

    this.commit('toggleLoading', true, { root: true })

    const config = configParam || this.getConfig()

    return axios.patch(targetUrl, payload, config).then(
        (result) => {
            this.commit('toggleLoading', false, { root: true })
            return result
        }
    ).catch(
        (er) => {
            this.commit('toggleLoading', false, { root: true })
            return { error: er, errorData: er.data }
        }
    )
}

RequestHandler.prototype.createTarget = async function (targetUrl, payload, configParam = false){
    //  Handle the process of updating an item in the server
    const validToken = await this.verifyToken()
    if (!validToken) {
        const exception = { "status": 404 }
        return exception
    }

    this.commit('toggleLoading', true, { root: true })

    const config = configParam || this.getConfig()

    return axios.post(targetUrl, payload, config).then(
        (result) => {
            this.commit('toggleLoading', false, { root: true })
            return result
        }
    ).catch(
        (er) => {
            this.commit('toggleLoading', false, { root: true })
            return { error: er, errorData: er.data }
        }
    )
}

export { RequestHandler }